exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-cv-english-tsx": () => import("./../../../src/pages/CvEnglish.tsx" /* webpackChunkName: "component---src-pages-cv-english-tsx" */),
  "component---src-pages-cv-nederlands-tsx": () => import("./../../../src/pages/CvNederlands.tsx" /* webpackChunkName: "component---src-pages-cv-nederlands-tsx" */),
  "component---src-pages-en-tsx": () => import("./../../../src/pages/en.tsx" /* webpackChunkName: "component---src-pages-en-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kunstwerken-tsx": () => import("./../../../src/pages/kunstwerken.tsx" /* webpackChunkName: "component---src-pages-kunstwerken-tsx" */),
  "component---src-pages-tentoonstellingen-tsx": () => import("./../../../src/pages/tentoonstellingen.tsx" /* webpackChunkName: "component---src-pages-tentoonstellingen-tsx" */),
  "component---src-templates-categorie-tsx": () => import("./../../../src/templates/categorie.tsx" /* webpackChunkName: "component---src-templates-categorie-tsx" */),
  "component---src-templates-kunstwerk-tsx": () => import("./../../../src/templates/kunstwerk.tsx" /* webpackChunkName: "component---src-templates-kunstwerk-tsx" */)
}

